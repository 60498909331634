import React, { useState } from 'react'
import './navigation.scss'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from 'gatsby'
import { SignedIn, SignedOut } from '@clerk/clerk-react'

type SinglePage = {
  url: string
  title: string
}

type ParentPage = {
  title: string
  id: string
  url: string
  children: SinglePage[]
}

type Page = SinglePage | ParentPage

const Navigation = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const pages: Page[] = [
    { url: 'pages/how-it-works', title: 'how it works' },
    { url: 'pages/pricing', title: 'pricing' },
    { url: 'pages/how-to-use', title: 'how to use' },
    {
      title: 'clients',
      url: 'pages/clients',
      id: 'client-dropdown',
      children: [
        { url: 'pages/clients#kinopio', title: 'kinopio' },
        { url: 'pages/clients#logseq', title: 'logseq' },
        { url: 'pages/clients#roam', title: 'roam' },
      ],
    },
    { url: 'pages/resources', title: 'resources' },
    {
      id: 'update-dropdown',
      title: 'updates',
      url: 'changelog',
      children: [
        { url: 'changelog', title: 'changelog' },
        { url: 'blog', title: 'blog' },
      ],
    },
  ]

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item logo-item" to="/">
          <img src="/images/logo.svg" width="80" height="80" alt="ptr logo" />
        </Link>

        <button
          className={`navbar-burger ${hamburgerOpen ? 'is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="ptr-static-navbar"
          onClick={() => setHamburgerOpen(!hamburgerOpen)}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div
        id="ptr-static-navbar"
        className={`navbar-menu ${hamburgerOpen ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          {pages.map((page: Page) => {
            if ('children' in page === false) {
              return (
                <Link
                  key={page.url}
                  className="navbar-item"
                  to={`/${page.url}`}
                  activeClassName="active"
                >
                  {page.title}
                </Link>
              )
            } else if ('id' in page) {
              return (
                <div
                  key={page.title}
                  id={page.id}
                  className="navbar-item has-dropdown is-hoverable"
                >
                  <span className="navbar-link">
                    <Link
                      key={page.url}
                      className="navbar-item"
                      to={`/${page.url}`}
                      activeClassName="active"
                    >
                      {page.title}
                      <FiChevronDown className="chevron-down" />
                    </Link>{' '}
                  </span>
                  <div className="navbar-dropdown">
                    {page.children.map((child: SinglePage) => (
                      <Link
                        key={child.url}
                        className="navbar-item"
                        to={`/${child.url}`}
                        activeClassName="active"
                      >
                        {child.title}
                      </Link>
                    ))}
                  </div>
                </div>
              )
            }
          })}
        </div>

        <div className="navbar-end">
          <SignedIn>
            <a
              href={`${process.env.GATSBY_PTN_DX}/`}
              className="button is-secondary"
              style={{
                marginTop: '12px',
                marginLeft: '6px',
                marginBottom: '12px',
              }}
            >
              dashboard
            </a>
          </SignedIn>
          <SignedOut>
            <a
              className="navbar-item"
              href={`https://${process.env.GATSBY_CLERK_SIGN_IN_URL}?redirect_url=${process.env.GATSBY_PTN_DX}/`}
            >
              sign in
            </a>{' '}
            <a
              className="button is-primary is-outlined"
              href={`https://${process.env.GATSBY_CLERK_SIGN_UP_URL}?redirect_url=${process.env.GATSBY_PTN_DX}/`}
            >
              sign up
            </a>
          </SignedOut>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
