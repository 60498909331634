import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer className="container is-widescreen nudge">
    <div className="ptr-footer">
      <div className="ptr-footer-links">
        <Link to="/pages/about">about</Link>
        <Link to="/pages/privacy">privacy</Link>
        <Link to="/pages/faq">support</Link>
        <a href="https://twitter.com/phonetonote">twitter</a>
      </div>
    </div>
  </footer>
)

export default Footer
