import * as React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({
  description = 'quickly capture your thoughts, as a service',
  lang = 'en',
  meta = [],
  title = 'phonetonote',
  image = `https://www.phonetonote.com/images/og-image.png`,
}) => {
  const defaultTitle = 'phonetonote'

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title.toLocaleLowerCase()}
        defaultTitle={defaultTitle ? `%s | ${defaultTitle}` : undefined}
        titleTemplate={
          title === defaultTitle ? undefined : `${defaultTitle} | %s`
        }
        link={[
          {
            rel: 'icon',
            href: 'https://www.phonetonote.com/favicon.ico',
            sizes: 'any',
          },
          {
            rel: 'icon',
            href: 'https://www.phonetonote.com/images/logo.svg',
            type: 'image/svg+xml',
          },
          {
            rel: 'apple-toch-icon',
            href: 'https://www.phonetonote.com/images/apple-touch-icon.png',
          },
        ]}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            name: `image`,
            content: image,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: image,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`,
          },
          {
            name: `twitter:image`,
            content: `https://www.phonetonote.com/images/twitter-image.png`,
          },
          {
            name: `twitter:creator`,
            content: '@phonetonote',
          },
          {
            name: `twitter:site`,
            content: '@phonetonote',
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: description,
          },
        ].concat(meta)}
      />
    </>
  )
}

export default Seo
